import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-err',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './err.component.html',
  styleUrl: './err.component.scss'
})
export class ErrComponent {

}
